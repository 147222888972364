<template>
  <div class="commentCnt">
    <div
      class="comment"
      v-for="(comment, i) in comments.slice(Math.max(comments.length - 3, 0))"
      :key="i"
    >
      <div>
        <vs-avatar circle size="30">
          <img loading="lazy" :src="comment.userImg" alt="Avatar" />
        </vs-avatar>
      </div>
      <div class="commentText">
        <div class="smallData">
          <div>
            <small
              ><b>{{ comment.user }}</b></small
            >
          </div>
          <div class="smallDataInfo">
            <small>{{ comment.likes }} Likes</small>
            <small
              ><em>{{ comment.time }}</em></small
            >
          </div>
          <vs-button transparent mini @click="addLike(comment.id)">
            <i v-if="true" class="far fa-heart fa-xs"></i>
          </vs-button>
        </div>
        <div class="textAndReply">
          <p class="paddingLess">{{ comment.text }}</p>
          <vs-button class="paddingLess" transparent mini @click="showReply(comment.id)">
            Responder
          </vs-button>
        </div>

        <div class="reply">
          <div v-if="showReplyId === comment.id" style="display:flex">
            <vs-input
              @keypress.enter="addReply(comment.id)"
              placeholder="Escribe aquí tu respuesta"
            ></vs-input>
            <vs-button icon relief success animation-type="rotate" @click="setData"
              >Enviar</vs-button
            >
          </div>
          <p>Respuesta {{i}}</p>
        </div>
      </div>
    </div>
    <b-form-textarea
      class="divRow"
      id="textarea"
      placeholder="Escribe aquí tu comentario..."
      rows="1"
      max-rows="6"
      v-model="currentComment"
    ></b-form-textarea>
    <vs-button icon relief success block animation-type="rotate" @click="setData">Enviar</vs-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "commentMsg",
  // props: ["comments"],
  data() {
    return {
      currentComment: "",
      comments: [],
      showReplyId: null
    };
  },
  methods: {
    async setData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const id = this.$store.state.currentEvent.id;
      const user = this.$store.state.currentUser;

      if (id && user.id) {
        const body = { event_id: id, author_id: user.id, comment: this.currentComment };

        await apiClient
          .post("/comments", body)
          .then(response => {
            var parsedComment = {
              id: response.data.id,
              user: user.nick,
              userImg: user.ProfileImg,
              likes: 0,
              time: new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
              }),
              text: this.currentComment
            };
            this.comments.push(parsedComment);
            this.currentComment = "";
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    async getData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      const id = this.$store.state.currentEvent.id;
      if (id) {
        const body = {
          params: { event_id: id }
        };

        await apiClient
          .get("/comments", body)
          .then(response => {
            var data = response.data;
            if (data) {
              var assistants = this.$store.state.currentEvent.assistants;
              data.forEach(comment => {
                var ind = assistants.findIndex(a => a.id === comment.Author_id);
                if (ind >= 0) {
                  var parsedComment = {
                    id: comment.id,
                    user: assistants[ind].nick,
                    userImg: assistants[ind].ProfileImg,
                    likes: comment.likes,
                    time: new Date(comment.updated_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit"
                    }),
                    text: comment.Comment
                  };
                  this.comments.push(parsedComment);
                }
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    async addLike(id) {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const event_id = this.$store.state.currentEvent.id;
      if (id && event_id) {
        const body = { id, event_id, likes: 1 };
        await apiClient
          .post("/comments", body)
          .then(response => {
            this.comments.forEach(comment => {
              if (comment.id === id) {
                comment.likes += 1;
              }
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    showReply(id) {
      this.showReplyId = id;
    },

    async addReply(id) {
      const ind = this.comments.findIndex(c => {
        return c.id === id;
      });
      if (this.comments[ind].replies) {
        this.comments.push({
          user: user.nick,
          userImg: user.ProfileImg,
          likes: 0,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
          }),
          text: this.currentComment
        });
      }
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
.commentCnt {
  margin-bottom: 2rem;
}

.comment {
  display: flex;
  margin: 0rem 0 1rem;
  /* margin: 0.5rem 0; */
  /* padding: 0.25rem; */
  /* box-shadow: 2px 2px 12px 2px var(--dark); */
  /* border-radius: 0.5rem; */
}

.commentText {
  width: 100%;
}

.paddingLess {
  padding: 0;
  margin: 0;
}

.text {
  /* padding: 0.25rem 0.5rem; */
  /* border-left: 2px solid crimson; */
  font-size: smaller;
  text-align: justify;
  width: 100%;
  border-radius: 0.3rem;
  background: transparent;
  border: none;
}

.textAndReply {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.reply {
  margin-left: 10px;
  font-size: smaller;
  text-align: justify;
  width: 100%;
  border-radius: 0.3rem;
  background: transparent;
  border: none;
}

.smallData {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
}
.smallDataInfo {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  width: 80%;
}
</style>
