<template>
  <timeline timeline-theme="black" timeline-bg="white">
    <timeline-title>Agenda</timeline-title>
    <timeline-item bg-color="#9dd8e0">Parkineo</timeline-item>
    <timeline-item :hollow="true">Urban session</timeline-item>
    <timeline-item icon-size="medium" line-color="green" font-color="green"
      >Club session</timeline-item
    >
    <timeline-item :hollow="true"
      >House session
      <i class="fas fa-door-closed" alt="Logout" slot="others"></i>
    </timeline-item>
    <timeline-item :hollow="true">Remember session</timeline-item>
  </timeline>
</template>

<script>
export default {
  name: "agendaTL",
  props: ["agenda"],
  data() {
    return {};
  }
};
</script>
