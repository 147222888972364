<template>
  <div>
    <vs-button
      icon
      relief
      floating
      color="#2c2a4a"
      block
      animation-type="rotate"
      v-b-toggle.collapse-block-1
    >
      <i class="fas fa-chess"></i> Servicios
      <template #animate>
        <i class="fas fa-chess"></i>
      </template>
    </vs-button>
    <b-collapse id="collapse-block-1" visible  class="mt-2">
      <ServicesPreview />
    </b-collapse>
    <vs-button
      icon
      relief
      floating
      color="#DA3E52"
      block
      animation-type="rotate"
      v-b-toggle.collapse-block-2
    >
      <i class="fas fa-comment-dots"></i> Comentarios
      <template #animate>
        <i class="fas fa-comment-dots"></i>
      </template>
    </vs-button>
    <b-collapse id="collapse-block-2" visible  class="mt-2">
      <b-card style="width:100%">
        <CommentMsg />
      </b-card>
    </b-collapse>
    <vs-button
      icon
      relief
      floating
      color="#d68c45"
      block
      animation-type="rotate"
      v-b-toggle.collapse-block-3
    >
      <i class="fas fa-icons"></i> Media
      <template #animate>
        <i class="fas fa-icons"></i>
      </template>
    </vs-button>
    <b-collapse id="collapse-block-3" visible  class="mt-2">
      <b-card style="width:100%">
        <MediaSlider />
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import MediaSlider from "@/components/MediaSlider.vue";
import ServicesPreview from "@/components/SERVICES/ServicesPreview.vue";
import CommentMsg from "@/components/CommentMsg.vue";

export default {
  name: "eventBlocksPreview",
  components: {
    MediaSlider,
    ServicesPreview,
    CommentMsg
  },
  data() {
    return {};
  }
};
</script>
