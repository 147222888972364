<template>
  <div>
    <EventSettingsSideBar @onSave="onSave" :event="this.event" :open="true" />
    <b-container class="bv-example-row">
      <b-row class="infoRow">
        <b-col cols="12" md="5" order="2" order-md="1" class="col1" style="text-align: start;">
          <div class="divRow1">
            <vs-card type="1" :loading="loading.eventImg" class="eventInfo">
              <template #title>
                <div class="titleCard" style="display:flex; justify-content:space-between;">
                  <h2>{{ event.title }}</h2>
                  <div>{{ event.aforo }} Plazas</div>
                  <small>
                    <!-- <b><i class="fas fa-users">.</i></b> -->
                    {{ event.aforo }} Plazas
                  </small>
                </div>
              </template>
              <template #img>
                <img :src="eventImg" />
              </template>
            </vs-card>
            <vs-card type="1" :loading="loading.eventImg" class="eventInfo">
              <template #title>
                <h2>{{ event.title }}</h2>
              </template>
              <!-- <template #img>
                <img :src="eventImg" />
              </template> -->
              <template #text>
                <div
                  style="font-style: italic;
font-weight: 300;
font-size: 12px;
line-height: 12px;"
                >
                  <i class="fas fa-clock"></i>
                  <small> Fecha : {{ event.startDate }} - {{ event.endDate }}</small>
                </div>
                <div>
                  <i class="fas fa-map-marker-alt"></i>
                  <small>
                    <b> {{ event.street.includes(event.city) ? "" : event.city }}</b>
                  </small>
                  <small>
                    {{ event.street }}
                  </small>
                  <em @click="showMap = !showMap" style="color:blue; text-decoration:underline">
                    Maps
                  </em>
                </div>
                <p>
                  <small> {{ event.description }} </small>
                </p>
              </template>
              <template #interactions>
                <b-form-file
                  ref="avatarInput"
                  class="inputAvatar"
                  :state="Boolean(true)"
                  accept="image/*"
                  @change="onUploadFile($event)"
                ></b-form-file>
                <vs-button
                  v-if="isEditMode"
                  @click="onChangeImg($event)"
                  :loading="loading.eventImg"
                  class="btn-chat"
                  shadow
                  icon
                  primary
                >
                  <i class="fas fa-pencil-alt"></i>
                </vs-button>
                <vs-button v-else class="btn-chat" shadow primary>
                  <span class="span">
                    54
                  </span>
                  <i style="font-size: 0.5rem;" class="fas fa-eye fa-xs"></i>
                  <template #animate>
                    <i class="fas fa-eye"></i>
                  </template>
                </vs-button>

                <vs-button v-if="!isEditMode" class="btn-chat" shadow primary>
                  <span class="span">
                    {{ event.aforo }}
                  </span>
                  <i style="font-size: 0.5rem;" class="fas fa-users fa-xs"></i>
                  <template #animate>
                    <i class="fas fa-users"></i>
                  </template>
                </vs-button>
              </template>
            </vs-card>
          </div>
          <div class="divRow" v-if="showMap">
            <vs-card type="2" :loading="loading.eventImg" class="eventInfo2">
              <template #title>
                <h2>{{ event.title }}</h2>
              </template>
              <template #img>
                <iframe
                  :src="
                    `https://maps.google.com/maps?q=${
                      event.lat ? event.lat.toString() : event.lat
                    },${
                      event.long ? event.long.toString() : event.long
                    }&hl=es&z=14&amp;output=embed`
                  "
                  width="150"
                  frameborder="0"
                  style="border:0; width:100%;"
                  allowfullscreen="true"
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </template>
            </vs-card>
            <!-- <vs-card type="3" id="section2" class="eventMapInfo">
              <template #title>
                <h3>Ubicación</h3>
              </template>
              <template #text>
                <div>
                  <i class="fas fa-clock"></i>
                  <small> Fecha : {{ event.startDate }} - {{ event.endDate }}</small>
                </div>
                <div>
                  <i class="fas fa-map-marker-alt"></i>
                  <small>
                    Ubicación : <b> {{ event.city }}</b>
                  </small>
                </div>
                <div>
                  <small>
                    {{ event.street }}
                  </small>
                </div>
              </template>
            </vs-card> -->
          </div>

          <!-- <i>{{ isPrivate && isExternal ? "Calle falsa 123" : "Nombre del evento" }}</i> -->
        </b-col>
        <!-- <b-col cols="12" md="7" order="1" order-md="2" class="btnCol"> -->
        <!-- <EventBlocksPreview /> -->
        <!-- </b-col> -->
      </b-row>
      <b-row style="margin:0" class="contentRow">
        <div
          style="display: flex; justify-content: flex-end; width: 100%;
    padding: 0rem;"
        >
          <div style="padding: 0 0.5rem;">
            <vs-button
              v-if="!isJoined && !isEditMode && !isExternal"
              @click="onJoin"
              gradient
              size="mini"
              danger
              animation-type="scale"
            >
              Join
              <template #animate>
                Join
              </template>
            </vs-button>
            <vs-button
              v-if="isJoined"
              @click="onDisJoin"
              gradient
              size="mini"
              danger
              animation-type="scale"
            >
              Disjoin
              <template #animate>
                Disjoin
              </template>
            </vs-button>
          </div>
        </div>
      </b-row>
      <b-row class="contentRow">
        <b-tabs v-model="selectedTab" id="mainTabs" style="width:100%;" justified fill>
          <b-tab :active="selectedTab === 0" title="Muro" lazy>
            <template v-if="1 == 'loading'" v-slot:title>
              Progress
              <PacmanLoader v-if="1 == 'loading'" color="#dc3545" size="10px" />
            </template>
            <template v-else v-slot:title>
              <!-- <iWall /> -->
              <small>Muro</small>
            </template>
            <b-row style="margin:1rem">
              <ProgressTL :currentUser="currentUser" />
            </b-row>
          </b-tab>
          <b-tab :active="selectedTab === 1" title="Media" lazy>
            <template v-if="1 == 'loading'" v-slot:title>
              Media
              <PacmanLoader v-if="1 == 'loading'" color="#dc3545" size="10px" />
            </template>
            <template
              style="text-decoration-thickness: 3px;
    text-decoration-style: initial;
    text-decoration-color: #e91e63;"
              v-else
              v-slot:title
            >
              <!-- <iMoments /> -->
              <small>Momentos</small>
            </template>
            <b-row style="margin:1rem">
              <MediaList />
            </b-row>
          </b-tab>
          <b-tab :active="selectedTab === 2" title="Servicios" lazy>
            <template v-if="1 == 'loading'" v-slot:title>
              Servicios
              <PacmanLoader v-if="1 == 'loading'" color="#dc3545" size="10px" />
            </template>
            <template v-else v-slot:title>
              <!-- <iElements /> -->
              <small>Servicios</small>
            </template>
            <b-row style="margin:1rem">
              <div class="serviceCnt" id="serviceCnt">
                <h1>Servicios</h1>
                <!-- ASK AND ANSWER -->
                <b-collapse id="service-stats" appear class="mt-2">
                  <StatsService :event_id="this.event.id" :owner="!isExternal" />
                </b-collapse>
                <!-- LIST -->
                <b-collapse
                  id="service-list"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                  class="mt-2"
                >
                  <ListService :lists="lists" :owner="!isExternal" />
                </b-collapse>
                <!-- POLLS -->
                <b-collapse id="service-vote" visible class="mt-2">
                  <PollsService :polls="polls" :owner="!isExternal" />
                </b-collapse>
                <!-- ASK AND ANSWER -->
                <b-collapse id="service-AandA" visible class="mt-2">
                  <AandAService :questions="questions" :owner="!isExternal" />
                </b-collapse>
                <!-- PAYMENTS -->
                <!-- <b-collapse id="service-payment" visible class="mt-2">
                  <PaymentService />
                </b-collapse> -->
                <!-- <ServicesFB /> -->
              </div>
            </b-row>
          </b-tab>
          <b-tab :active="selectedTab === 3" title="Admin" lazy v-if="isAdmin">
            <template v-if="1 == 'loading'" v-slot:title>
              Servicios
              <PacmanLoader v-if="1 == 'loading'" color="#dc3545" size="10px" />
            </template>
            <template v-else v-slot:title>
              <!-- <iCreate /> -->
              <small>Admin</small>
            </template>
            <vs-button
              icon
              block
              size="mini"
              v-if="!isEditMode && !isExternal"
              @click.stop="onInvite"
              relief
              color="amazon"
              animation-type="rotate"
            >
              <i class="fas fa-user-plus"></i> Invite
              <template #animate>
                <i class="fas fa-user-plus"></i>
              </template>
            </vs-button>
            <vs-button
              icon
              size="mini"
              v-if="!isEditMode && !isExternal"
              @click="onEdit"
              relief
              block
              color="primary"
              animation-type="rotate"
            >
              <i class="fas fa-pen"></i> Edit
              <template #animate>
                <i class="fas fa-pen"></i>
              </template>
            </vs-button>
            <!-- v-else-if="!isExternal" -->
            <vs-button
              icon
              size="mini"
              v-else-if="isEditMode && !isExternal"
              relief
              success
              @click="onSave"
              animation-type="rotate"
            >
              <i class="fas fa-save"></i> Save
              <template #animate>
                <i class="fas fa-save"></i>
              </template>
            </vs-button>
            <AdminService :participants="event.assistants" />
            Work in progress
          </b-tab>
          <!-- <template #tabs-end>
        
          </template> -->
        </b-tabs>
      </b-row>
    </b-container>
    <vs-dialog blur v-model="showInvite">
      <template #header>
        <h4 class="not-margin">
          Estas invitado al evento <b>{{ event.title }}</b>
        </h4>
      </template>
      <img v-if="eventImg" :src="eventImg" />
      <template #footer>
        <div class="footer-dialog">
          <vs-button warn block @click="onJoin">
            Unirme
          </vs-button>

          <div class="new">
            <vs-button danger size="mini" transparent>
              Volver
            </vs-button>
          </div>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, authMix, eventMix } from "@/common/mixin.js";
import EventSettingsSideBar from "@/components/SIDEBARS/EventSettingsSB.vue";
import AssistantsList from "@/components/AssistantsList.vue";
import AgendaTL from "@/components/AgendaTL.vue";
import ProgressTL from "@/components/ProgressTL.vue";
import MediaList from "@/components/MediaList.vue";
import EventBlocksPreview from "@/components/EventBlocksPreview.vue";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
import {
  AandAService,
  AdminService,
  ListService,
  PaymentService,
  PollsService,
  ServicesFB,
  StatsService
} from "@/components/SERVICES/";
import { iCreate, iWall, iMoments, iElements } from "@/components/ILLUSTRATIONS/";

export default {
  name: "Event",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix],
  components: {
    EventSettingsSideBar,
    EventBlocksPreview,
    AssistantsList,
    MediaList,
    AgendaTL,
    ProgressTL,
    ServicesFB,
    ListService,
    PaymentService,
    PacmanLoader,
    Timeline,
    TimelineItem,
    TimelineTitle,
    AandAService,
    AdminService,
    PollsService,
    StatsService,
    iWall,
    iMoments,
    iElements,
    iCreate
  },
  created() {},
  watch: {},
  methods: {
    onUploadFile(oEvent) {
      this.loading.eventImg = true;
      const image = oEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        this.eventImg = oEvent2.target.result;
        this.loading.eventImg = false;
      };
    },

    onChangeImg(oEvent) {
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
      if (this.isEditMode) {
        this.$refs.avatarInput.$el.children[0].click();
      }
    },

    onEdit() {
      this.$store.commit("showEventSettingsSB", true);
      this.isEditMode = true;
    },

    async onSave(info) {
      this.isEditMode = false;
      this.$store.commit("showEventSettingsSB", false);
      const { event, currentUser } = this;
      const { title, isPrivate, description, aforo, image } = info.data;
      this.eventImg = image ? image : this.eventImg;

      const data = info.data
        ? {
            title,
            isPrivate,
            description,
            aforo,
            image: this.eventImg
          }
        : event;
      this.event = { ...this.event, ...data };
      await this.updateEvents(event.id, data);
    },

    onInvite() {
      if (navigator.share) {
        navigator
          .share({
            title: "B YOUR PLAN || Nueva Invitación",
            url: window.location.href.concat("/" + this.event.id)
            // url: window.location.host.concat("/invite/" + this.event.inviteKey)
          })
          .then(() => {
            console.log("Thanks for sharing!");
          });
      }
    },

    async onJoin() {
      const { event, currentUser } = this;
      const { assistants, id } = event;
      const { uid, photoURL } = currentUser;
      if (uid) {
        const myUserUid = [uid.toString()];
        const isNewParticipant = assistants ? !assistants.includes(myUserUid) : true;
        event.assistants = assistants ? [...new Set(assistants.concat(...myUserUid))] : myUserUid;
        this.updateEvents(id, event);
        this.isJoined = true;
        if (assistants && isNewParticipant) {
          const data = {
            type: "participant",
            created_by: uid,
            created_at: new Date(),
            authorImg: photoURL
          };
          await this.addParticipantEvent(id, data);
        }
        this.showInvite = false;
        this.notificateLoginSuccess(
          "Unido con exito",
          "Te has unido con exito al evento " + event.title
        );
      } else {
        this.notificateLoginRequired();
      }
    },

    async onDisJoin() {
      const { event, currentUser } = this;
      const { assistants, id } = event;
      const { uid } = currentUser;
      if (uid) {
        const deletedInd = assistants.findIndex(assistant => assistant.id === uid);
        assistants.splice(deletedInd, 1);
        await this.updateEvents(id, event);
        this.isJoined = false;
      } else {
        this.notificateLoginRequired();
      }
    },

    async initEvent() {
      const { currentEvent } = this.$store.state;
      let actualEvent = {};
      let invited = false;
      if (!currentEvent.card) {
        const { id } = this.$route.params;
        if (typeof id !== "undefined") {
          actualEvent.card = await this.getEvent(id);
          actualEvent.card.id = id;
          invited = true;
        } else {
          this.$router.push({ name: "Search" });
        }
      } else {
        actualEvent = currentEvent;
      }
      const { card } = actualEvent;
      // const currentUser = await this.firebaseAuth(this.$store);
      const currentUser = await this.firebaseCurrentUser();
      this.currentUser = currentUser;
      const isCreator = currentUser.uid === card.created_by;
      this.isAdmin = isCreator;
      if (actualEvent && currentUser) {
        const user = currentUser.uid;
        this.isExternal = card.created_by !== user;
        this.isJoined = isCreator
          ? isCreator
          : card.assistants
          ? card.assistants.includes(user)
          : false;
        this.showInvite = !isCreator && !this.isJoined && invited;
        this.eventImg = card.image ? card.image : this.eventImg;
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        this.event = card;

        const { location } = this.event;
        if (location) {
          this.event.street = location.value;
          this.event.city = location.name;
          this.event.lat = location.latlng.lat;
          this.event.long = location.latlng.lng;
        }
        const { endDate, startDate } = card;
        this.event.endDate = endDate ? new Date(endDate).toLocaleDateString(_, options) : null;
        this.event.startDate = startDate
          ? new Date(startDate).toLocaleDateString(_, options)
          : null;
        this.lists = await this.getInteractionEvent(card.id, "list");
        this.questions = await this.getInteractionEvent(card.id, "questions");
        this.polls = await this.getInteractionEvent(card.id, "polls");
      } else if (!currentUser) {
        this.$router.push({ name: "Login" });
      } else {
        this.$router.push({ name: "Search" });
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    debugger;
    const { style } = document.getElementById("mainTabs").children[1];
    style.overflow = "auto";
    style.height = "calc(100vh - 125px)";
    this.initEvent();
  },
  data() {
    return {
      isEditMode: false,
      selectedTab: 0,
      currentUser: null,
      showInvite: false,
      showMap: false,
      loading: {
        eventImg: false
      },
      event: {
        title: "",
        description: "",
        street: "Calle falsa 123, Martorell",
        lugar: "",
        aforo: 0,
        assistants: [],
        latlng: { lat: 41.47, long: 1.92 }
      },
      isExternal: true,
      isAdmin: false,
      isJoined: false,
      lists: [],
      questions: [],
      polls: [],
      eventImg: "https://images-na.ssl-images-amazon.com/images/I/81jQ-AwnRcL._AC_SL1500_.jpg",
      avatarImg: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg",
      privateImg:
        "https://p1.pxfuel.com/preview/272/573/172/iphone-taking-photo-phone-anonymous-royalty-free-thumbnail.jpg",
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  }
};
</script>

<style scoped>
#mainTabs > div > ul {
  display: flex;
  align-items: center;
}

#mainTabs > div > ul > li {
  border-color: #e91e63 #e91e63 #fff;
  border-radius: 20% 20% 0 0;
}

img {
  max-height: 200px !important;
}

.inputAvatar {
  display: none;
}

.portadaRow {
  margin: 0;
}

.infoRow {
  margin: auto;
}

.divRow,
.divRow1 {
  display: flex;
  /* margin-top: 1rem;
  margin-bottom: 1rem; */
  justify-content: center;
}

.divRow1 {
  justify-content: flex-start;
}

/* .participantsList,
.serviceList {
  padding: 1rem;
  box-shadow: 5px 3px 20px 0px #17a2b8;
  border-radius: 2rem;
  margin: 1rem;
} */

.serviceCnt {
  width: 100%;
}

#serviceCnt .v-speed-dial {
  position: fixed;
}

#serviceCnt .v-btn--floating {
  position: relative;
}
</style>

<style>
.v-timeline::before {
  display: none;
}
.eventInfo > .vs-card > .vs-card__img img {
  max-width: 100px;
  max-height: 100px !important;
  min-width: 100px;
  height: 100%;
  object-fit: cover;
}

.eventInfo > .vs-card > .vs-card__img {
  min-width: 100px;
}

.eventInfo2 > .vs-card > .vs-card__img {
  min-width: 300px;
}

.eventInfo > .vs-card > .vs-card__text > .vs-card__title > h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.eventInfo > .vs-card > .vs-card__text {
  padding: 0 0.5rem;
}

.eventMapInfo > .vs-card > .vs-card__img {
  min-width: 100px;
  max-width: 175px;
  height: 100%;
  object-fit: cover;
}

.eventMapInfo > .vs-card > .vs-card__text {
  padding-top: 0;
}

.v-timeline--dense .v-timeline-item {
  justify-content: flex-end;
}

.v-timeline-item__divider {
  justify-content: flex-end;
  min-width: 50px;
}
</style>
