<template>
  <div style="width: 100%; text-align: start; ">
    <div class="mediaListHeader">
      <b>Comparte una foto para que la vean todos los asistentes</b>
      <vs-button circle icon floating @click.stop.prevent="addImg($event)">
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>
    <b-form-file
      ref="mediaInput"
      class="inputMedia"
      :state="Boolean(true)"
      accept="image/*"
      @change="onUploadFile($event)"
    ></b-form-file>
    <div class="gridCards3">
      <!-- <div v-for="(image, i) in images" :key="i">
        <vs-card type="2" @click="addVisit(image.id)">
          <template #img>
            <img :src="image.img" alt="" />
          </template>
          <template #interactions>
            <vs-button class="btn-chat" transparent warn>
              {{ image.visits }}<i style="font-size: 0.5rem;" class="fas fa-eye fa-xs"></i>
              <template #animate>
                <i class="fas fa-eye"></i>
              </template>
            </vs-button>
            <vs-button danger icon @click.stop="addLike(image.id)">
              {{ image.likes }}<i style="font-size: 0.5rem;" class="fas fa-heart fa-xs"></i>
              <template #animate>
                <i class="fas fa-heart fa-xs"></i>
              </template>
            </vs-button>
          </template>
        </vs-card>
      </div> -->
      <!-- <div>
        <small> Mejores momentos</small>
        <div class="bestMoments">
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
          <vs-avatar history history-gradient>
            <img height="100%" :src="this.avatarImg" alt="" />
          </vs-avatar>
        </div>
      </div> -->
      <div>
        <small>Recientes</small>
        <div>
          <vs-card type="2" v-for="(image, i) in moments" :key="i" @click="showInfo(image)">
            <template #title>
              <h3>Titulo</h3>
            </template>
            <template #img>
              <img :src="image.imageUrl" alt="" />
            </template>
            <template #text>
              <p>
                Descripcion de la foto
              </p>
            </template>
            <template #interactions>
              <vs-button v-if="!isLiked(image)" danger @click="addLike(null, image)">
                {{ image.likes + "&nbsp;" }} <i class="far fa-heart" />
              </vs-button>
              <vs-button v-else danger @click="removeLike(null, image)">
                {{ image.likes + "&nbsp;" }} <i class="fas fa-heart" />
              </vs-button>
            </template>
          </vs-card>
        </div>
      </div>
    </div>

    <vs-dialog not-close auto-width blur not-padding overflow-hidden v-model="active">
      <div class="con-image">
        <img
          width="100%"
          style="border-radius: 1rem;"
          :src="currentImg ? currentImg.imageUrl : null"
          alt=""
        />
      </div>
      <div class="con-info" v-if="currentImg">
        <!-- <h4>Titulo de la imagen</h4> -->
        <vs-row>
          <vs-col :w="2"><small>8 h</small></vs-col>
          <vs-col :w="4"
            ><small>{{ currentImg.likes }} Me gusta</small></vs-col
          >
        </vs-row>
        <v-text-field
          v-model="commentText"
          hide-details
          flat
          label="Responder..."
          solo
          @keydown.enter="sendReply"
        >
          <template v-slot:append>
            <vs-button
              icon
              v-if="commentText"
              @click="sendReply"
              relief
              size="mini"
              success
              animation-type="rotate"
            >
              Enviar
              <template #animate>
                <i class="fas fa-comment-alt"></i>
              </template>
            </vs-button>
          </template>
        </v-text-field>
        <vs-row class="reply" v-for="(reply, index) in currentImg.replies" :key="'reply_' + index">
          <vs-col :w="2">
            <v-avatar size="30">
              <img
                :src="
                  reply.authorImg
                    ? reply.authorImg
                    : 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown'
                "
              />
            </v-avatar>
          </vs-col>
          <vs-col :w="8">
            <div>
              <div>
                <small>
                  <b> {{ reply.username }} </b> {{ reply.value }}
                </small>
              </div>
              <vs-row>
                <vs-col :w="3"><small>8 h</small></vs-col>
                <vs-col :w="6"
                  ><small>{{ reply.likes }} Me gusta</small></vs-col
                >
              </vs-row>
            </div>
          </vs-col>
          <vs-col :w="2">
            <vs-button
              v-if="!isLiked(reply)"
              transparent
              danger
              @click="addLike(reply, currentImg)"
            >
              <i class="far fa-heart" />
            </vs-button>
            <vs-button v-else transparent danger @click="removeLike(reply, currentImg)">
              <i class="fas fa-heart" />
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { imageMix, eventMix, authMix } from "@/common/mixin.js";
// import CommentMsg from "./CommentMsg.vue";

export default {
  name: "mediaList",
  mixins: [imageMix, eventMix, authMix],
  props: ["media"],
  components: {
    // CommentMsg
  },
  data() {
    return {
      active: false,
      avatarImg:
        "https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown",
      images: [],
      moments: [],
      currentImg: null,
      commentText: null
    };
  },
  methods: {
    addImg(oEvent) {
      oEvent.cancelBubble = true;
      this.$refs.mediaInput.$el.children[0].click();
    },

    async onUploadFile(oEvent) {
      const currentUser = await this.firebaseCurrentUser();
      const { currentEvent } = this.$store.state;
      const { card } = currentEvent;
      const { uid, photoURL, username } = currentUser;
      // const { id } = currentUser;
      if (uid) {
        const image = oEvent.target.files[0];
        const imageUrl = await this.addMomentImg(card.id, image);
        await this.sendImageMoment(uid, imageUrl, card.id, photoURL);
        this.sendPush(`${username}: New photo`, [card.created_by], "Media");
      } else {
        this.notificateLoginRequired();
      }
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
    },

    sendImageMoment(uid, imageUrl, id, authorImg) {
      if (id) {
        const data = {
          type: "moment",
          created_by: uid,
          imageUrl,
          created_at: new Date(),
          likes: 0,
          replies: [],
          authorImg
        };
        this.addImageMomentEvent(id, data);
      } else {
        this.notificateLoginRequired();
      }
    },

    async addLike(reply, comment) {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id } = currentUser;
      if (id) {
        const myLike = [
          {
            created_by: id,
            created_at: new Date()
          }
        ];
        if (reply) {
          reply.likes += 1;
          reply.likeDetail = reply.likeDetail
            ? [...new Set(reply.likeDetail.concat(...myLike))]
            : myLike;
          const ind = comment.replies.findIndex(rep => {
            return rep.created_at === reply.created_at;
          });
          if (ind > -1) {
            comment.replies[ind] = reply;
          }
        } else {
          comment.likes += 1;
          comment.likeDetail = comment.likeDetail
            ? [...new Set(comment.likeDetail.concat(...myLike))]
            : myLike;
        }
        await this.updateMomentEvent(card.id, comment.id, comment);
      } else {
        this.notificateLoginRequired();
      }
    },

    async removeLike(reply, comment) {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id } = currentUser;
      if (id) {
        if (reply) {
          const deletedInd = reply.likeDetail.findIndex(detail => detail.created_by === id);
          reply.likeDetail.splice(deletedInd, 1);
          reply.likes -= 1;
          const ind = comment.replies.findIndex(rep => {
            return rep.created_at === reply.created_at;
          });
          if (ind > -1) {
            comment.replies[ind] = reply;
          }
        } else {
          const deletedInd = comment.likeDetail.findIndex(detail => detail.created_by === id);
          comment.likeDetail.splice(deletedInd, 1);
          comment.likes -= 1;
        }
        await this.updateMomentEvent(card.id, comment.id, comment);
      } else {
        this.notificateLoginRequired();
      }
    },

    isLiked(reply) {
      if (reply.likeDetail) {
        const { currentEvent, currentUser } = this.$store.state;
        const { id } = currentUser;
        const ind = reply.likeDetail.findIndex(detail => {
          return detail.created_by === id;
        });
        return ind > -1;
      } else {
        return false;
      }
    },

    async addVisit(id) {
      this.active = !this.active;
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const event_id = this.$store.state.currentEvent.id;
      if (id && event_id) {
        const body = { id, event_id, visit: 1 };
        await apiClient
          .post("/media", body)
          .then(response => {
            this.images.forEach(image => {
              if (image.id === id) {
                image.visits += 1;
              }
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    showInfo(image) {
      this.currentImg = image;
      this.active = true;
    },

    async sendReply() {
      const moment = this.currentImg;
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id, profileImg, username } = currentUser;
      if (card.id && id) {
        moment.replies.push({
          value: this.commentText,
          type: "reply",
          created_by: id,
          created_at: new Date(),
          likes: 0,
          username,
          authorImg: profileImg
        });
        await this.updateMomentEvent(card.id, moment.id, moment);
        this.commentText = "";
      } else {
        this.notificateLoginRequired();
      }
    },

    async getData() {
      const { currentEvent } = this.$store.state;
      const { card } = currentEvent;
      // this.images = await this.getMomentsImg(card.id);
      this.moments = await this.getImagesMomentsEvent(card.id);
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
.reply {
  text-align: justify;
}

@media (max-width: 575px) {
  .gridCards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 576px) {
  .gridCards {
    grid-template-columns: repeat(5, 1fr);
  }
}
.gridCards {
  display: grid;
  grid-gap: 10px;
}

.con-info {
  padding: 1rem;
}

.mediaListHeader {
  display: flex;
  justify-content: space-between;
}

.inputMedia {
  display: none;
}

.bestMoments {
  display: flex;
  justify-content: space-evenly;
}
</style>
