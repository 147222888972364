<template>
  <div>
    <div class="progressHeader">
      <!-- <b>
        Esta pasando...
      </b> -->
      <vs-button @click="showCreateAds" circle icon floating color="rgb(59,222,200)" gradient>
        <i class="fas fa-bullhorn"></i>
      </vs-button>
    </div>

    <v-timeline class="timeLine" align-top dense>
      <v-timeline-item color="#ff00ff" fill-dot>
        <template v-slot:icon>
          <v-avatar>
            <img
              :src="
                currentUser
                  ? currentUser.photoURL
                  : 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown'
              "
            />
          </v-avatar>
        </template>
        <v-text-field
          v-model="commentText"
          hide-details
          flat
          label="Dejar un comentario..."
          solo
          @keydown.enter="sendComment"
        >
          <template v-slot:append>
            <vs-button
              icon
              v-if="commentText"
              @click="sendComment"
              relief
              size="mini"
              success
              animation-type="rotate"
            >
              <i class="fas fa-comment-alt"></i> Enviar
              <template #animate>
                <i class="fas fa-comment-alt"></i>
              </template>
            </vs-button>
          </template>
        </v-text-field>
      </v-timeline-item>

      <v-timeline-item
        :color="
          comment.type === 'comment'
            ? '#46c93a'
            : comment.type === 'moment'
            ? '#ffba00'
            : comment.type === 'interaction'
            ? '#f83255'
            : comment.type
            ? 'rgb(59,222,200)'
            : '#000'
        "
        fill-dot
        v-for="(comment, ind) in allEvents"
        :key="'comment_' + ind"
      >
        <template v-slot:icon>
          <v-avatar>
            <img
              v-if="comment.authorImg && comment.type !== 'participant'"
              :src="comment.authorImg"
            />
            <i v-else-if="comment.type === 'comment'" class="fas fa-comment-dots"></i>
            <i v-else-if="comment.type === 'moment'" class="fas fa-camera"></i>
            <i v-else-if="comment.type === 'ads'" class="fas fa-bullhorn"></i>
            <i v-else-if="comment.type === 'interaction'" class="fas fa-gamepad"></i>
            <i v-else-if="comment.type === 'participant'" class="fas fa-user-plus"></i>
          </v-avatar>
        </template>
        <div v-if="comment.type === 'moment'">
          <vs-button size="mini" warn>
            <i class="fas fa-camera">·</i>
            Media
          </vs-button>
          <div>
            <vs-card type="2">
              <template #title>
                <h3>Ejemplo</h3>
              </template>
              <template #img>
                <img :src="comment.imageUrl" alt="" />
              </template>
              <template #text>
                <p>
                  Descripcion ejemplo.
                </p>
              </template>
              <!-- <template #interactions>
                <vs-button danger icon>
                  <i class="fas fa-heart"></i>
                </vs-button>
              </template> -->
            </vs-card>
          </div>
        </div>
        <div v-if="comment.type === 'comment'">
          <vs-button size="mini" success>
            <i class="fas fa-comment-dots">·</i>
            Comentario
          </vs-button>
          <div style="text-align: start;">
            {{ comment.value }}
          </div>
          <vs-button @click="showComment = ind" transparent size="mini" type="filled"
            >Responder</vs-button
          >
          <v-text-field
            v-model="commentText"
            v-if="showComment === ind"
            hide-details
            flat
            label="Responder..."
            solo
            @keydown.enter="sendReply(comment)"
          >
            <template v-slot:append>
              <vs-button
                icon
                v-if="commentText"
                @click="sendReply(comment)"
                relief
                size="mini"
                success
                animation-type="rotate"
              >
                Enviar
                <template #animate>
                  <i class="fas fa-comment-alt"></i>
                </template>
              </vs-button>
            </template>
          </v-text-field>
          <vs-row class="reply" v-for="(reply, index) in comment.replies" :key="'reply_' + index">
            <vs-col :w="2">
              <v-avatar size="30">
                <img
                  :src="
                    reply.authorImg
                      ? reply.authorImg
                      : 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown'
                  "
                />
              </v-avatar>
            </vs-col>
            <vs-col :w="8">
              <div>
                <div>
                  <small>
                    <b> {{ reply.username }} </b> {{ reply.value }}
                  </small>
                </div>
                <vs-row>
                  <vs-col :w="3"><small>8 h</small></vs-col>
                  <vs-col :w="6"
                    ><small>{{ reply.likes }} Me gusta</small></vs-col
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col :w="2">
              <vs-button v-if="!isLiked(reply)" transparent danger @click="addLike(reply, comment)">
                <i class="far fa-heart" />
              </vs-button>
              <vs-button v-else transparent danger @click="removeLike(reply, comment)">
                <i class="fas fa-heart" />
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
        <div v-if="comment.type === 'ads'">
          <vs-button size="mini" color="rgb(59,222,200)" gradient>
            <i class="fas fa-bullhorn">·</i>
            Anuncio
          </vs-button>
          <div style="text-align: start;">
            {{ comment.value }}
          </div>
        </div>
        <div v-if="comment.type === 'participant'">
          <!-- <vs-button size="mini" color="rgb(59,222,200)" gradient> -->
          <i class="fas fa-user-plus"></i>
          <!-- Anuncio -->
          <!-- </vs-button> -->
          <div style="text-align: start;">
            <v-row class="pt-1">
              <v-col>
                <strong>Nuevos Participantes</strong>
                <div class="caption mb-2">
                  Usuarios
                </div>
                <v-avatar>
                  <v-img :src="comment.authorImg" />
                </v-avatar>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="comment.type === 'interaction'">
          <vs-button size="mini" color="#f83255" gradient>
            <i class="fas fa-gamepad">·</i>
            Interacción
          </vs-button>
          <div style="text-align: start;">
            {{
              comment.subType === "questions"
                ? "Nueva Encuesta"
                : comment.subType === "polls"
                ? "Nueva Encuesta"
                : comment.subType === "list"
                ? "Nueva Lista"
                : "Nuevo Micropago"
            }}
          </div>
        </div>
      </v-timeline-item>

      <v-timeline-item color="dark" fill-dot>
        <i class="fas fa-user-plus"></i>
        <template v-slot:icon>
          <v-avatar>
            <i class="fas fa-user-plus"></i>
          </v-avatar>
        </template>
        <v-row class="pt-1">
          <v-col>
            <strong>Nuevos Participantes</strong>
            <div class="caption mb-2">
              Usuarios
            </div>
            <v-avatar>
              <v-img
                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
              ></v-img>
            </v-avatar>
            <v-avatar>
              <v-img
                src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
              ></v-img>
            </v-avatar>
            <v-avatar>
              <v-img
                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
              ></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-timeline-item>

      <v-timeline-item color="#46c93a" fill-dot>
        <template v-slot:icon>
          <i class="fas fa-comment-dots"></i>
        </template>
        <div>
          <vs-button size="mini" success>
            Comentario
          </vs-button>
          <div>
            He escrito un comentario que se añade en la lista del muro
          </div>
        </div>
      </v-timeline-item>

      <v-timeline-item color="rgb(59,222,200)" fill-dot>
        <template v-slot:icon>
          <i class="fas fa-bullhorn"></i>
        </template>
        <div>
          <vs-button size="mini" color="rgb(59,222,200)" gradient>
            Anuncio
          </vs-button>
          <div>
            Vamos a crear una lista, Apuntar os!
          </div>
        </div>
      </v-timeline-item>

      <v-timeline-item color="#f83255" fill-dot>
        <template v-slot:icon>
          <i class="fas fa-gamepad"></i>
        </template>
        <div>
          <vs-button size="mini" danger>
            Interacción
          </vs-button>
          <div>
            Nueva lista
          </div>
        </div>
      </v-timeline-item>
      <v-timeline-item color="#ffba00" fill-dot>
        <template v-slot:icon>
          <i class="fas fa-camera"></i>
        </template>
        <div>
          <vs-button size="mini" warn>
            Media
          </vs-button>
          <div>
            <vs-card type="2">
              <template #title>
                <h3>Ejemplo</h3>
              </template>
              <template #img>
                <img
                  :src="
                    'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown'
                  "
                  alt=""
                />
              </template>
              <template #text>
                <p>
                  Descripcion ejemplo.
                </p>
              </template>
              <template #interactions>
                <vs-button danger icon>
                  <i class="bx bx-heart"></i>
                </vs-button>
                <vs-button class="btn-chat" shadow primary>
                  <i class="bx bx-chat"></i>
                  <span class="span">
                    54
                  </span>
                </vs-button>
              </template>
            </vs-card>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <vs-dialog not-center v-model="active">
      <template #header>
        <h4 class="not-margin">{{ mode === 1 ? "Editar" : "Nuevo" }} anuncio!</h4>
      </template>

      <div class="con-content">
        <vs-input label-placeholder="Escribe tu anuncio" v-model="currentAds"></vs-input>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="active = false" danger>
            Cerrar
          </vs-button>
          <vs-button @click="createAds" transparent success>
            Guardar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { imageMix, authMix, eventMix, chatMix } from "@/common/mixin.js";

export default {
  name: "progressTL",
  mixins: [imageMix, authMix, eventMix, chatMix],
  props: ["progress", "currentUser"],
  data() {
    return {
      ads: [],
      images: [],
      comments: [],
      allEvents: [],
      currentAds: null,
      mode: 0,
      active: false,
      commentText: null,
      showComment: null
    };
  },
  watch: {
    allEvents: function(newValue, oldValue) {
      this.allEvents = newValue;
    }
  },
  methods: {
    async getData() {
      const { currentEvent } = this.$store.state;
      const { card } = currentEvent;
      if (card) {
        this.comments = await this.getCommentsEvent(card.id);
        this.images = await this.getImagesMomentsEvent(card.id);
        this.ads = await this.getAdsEvent(card.id);
        this.participants = await this.getParticipantsEvent(card.id);
        this.interactions = await this.getInteractionEvent(card.id);
        this.allEvents = this.images
          .concat(...this.comments)
          .concat(...this.ads)
          .concat(...this.participants)
          .concat(...this.interactions);
        this.allEvents.sort((a, b) => {
          return b.created_at - a.created_at;
        });
      }
    },

    async sendComment() {
      debugger;
      const currentUser = await this.firebaseCurrentUser();
      const { currentEvent } = this.$store.state;
      const { card } = currentEvent;
      const { uid, photoURL } = currentUser;
      const { username } = this.$store.state.currentUser;
      if (uid) {
        const data = {
          type: "comment",
          created_by: uid,
          value: this.commentText,
          created_at: new Date(),
          likes: 0,
          replies: [],
          authorImg: photoURL
        };
        await this.addCommentEvent(card.id, data);
        this.sendPush(`${username}: ${this.commentText}`, [card.created_by], "Reply");
        this.commentText = null;
        this.allEvents.push(data);
        this.allEvents.sort((a, b) => {
          return b.created_at - a.created_at;
        });
      } else {
        this.notificateLoginRequired();
      }
    },

    async sendReply(comment) {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id, profileImg, username } = currentUser;
      if (card.id && id) {
        comment.replies.push({
          value: this.commentText,
          type: "reply",
          created_by: id,
          created_at: new Date(),
          likes: 0,
          username,
          authorImg: profileImg
        });
        await this.updateCommentEvent(card.id, comment.id, comment);
        this.sendPush(`${username}: ${this.commentText}`, [comment.created_by], "Reply");
        this.commentText = "";
        this.showComment = null;
      } else {
        this.notificateLoginRequired();
      }
    },

    async addLike(reply, comment) {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id } = currentUser;
      if (id) {
        const myLike = [
          {
            created_by: id,
            created_at: new Date()
          }
        ];
        reply.likes += 1;
        reply.likeDetail = reply.likeDetail
          ? [...new Set(reply.likeDetail.concat(...myLike))]
          : myLike;
        const ind = comment.replies.findIndex(rep => {
          return rep.created_at === reply.created_at;
        });
        if (ind > -1) {
          comment.replies[ind] = reply;
        }
        await this.updateCommentEvent(card.id, comment.id, comment);
      } else {
        this.notificateLoginRequired();
      }
    },

    async removeLike(reply, comment) {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id } = currentUser;
      if (id) {
        const deletedInd = reply.likeDetail.findIndex(detail => detail.created_by === id);
        reply.likeDetail.splice(deletedInd, 1);
        reply.likes -= 1;
        const ind = comment.replies.findIndex(rep => {
          return rep.created_at === reply.created_at;
        });
        if (ind > -1) {
          comment.replies[ind] = reply;
        }
        await this.updateCommentEvent(card.id, comment.id, comment);
      } else {
        this.notificateLoginRequired();
      }
    },

    isLiked(reply) {
      if (reply.likeDetail) {
        const { currentEvent, currentUser } = this.$store.state;
        const { id } = currentUser;
        const ind = reply.likeDetail.findIndex(detail => {
          return detail.created_by === id;
        });
        console.log(ind);
        return ind > -1;
      } else {
        return false;
      }
    },

    showCreateAds() {
      this.active = true;
    },

    async createAds() {
      const currentUser = await this.firebaseCurrentUser();
      const { currentEvent } = this.$store.state;
      const { card } = currentEvent;
      const { uid } = currentUser;
      if (uid) {
        const data = {
          type: "ads",
          created_by: uid,
          value: this.currentAds,
          created_at: new Date(),
          likes: 0,
          authorImg: currentUser.photoURL
        };
        await this.addAdsEvent(card.id, data);
        this.allEvents.push(data);
        this.allEvents.sort((a, b) => {
          return b.created_at - a.created_at;
        });
      } else {
        this.notificateLoginRequired();
      }
      this.currentAds = null;
      this.active = false;
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
.progressHeader {
  display: flex;
  justify-content: flex-end;
}

.timeLine:before {
  left: 3rem;
}

.con-footer {
  display: flex;
  justify-content: space-between;
}

.reply {
  text-align: start;
  padding-left: 1rem;
}
</style>
