<template>
  <vs-card-group>
    <vs-card type="2" v-for="(image, i) in images.slice(Math.max(images.length - 3, 0))" :key="i">
      <template #img>
        <img :src="image.img" alt="Image" />
      </template>
      <template #interactions>
        <vs-button class="btn-chat" transparent warn>
          {{ image.visits }}<i style="font-size: 0.5rem;" class="fas fa-eye fa-xs"></i>
          <template #animate>
            <i class="fas fa-eye"></i>
          </template>
        </vs-button>
        <vs-button danger icon @click.stop="addLike(image.id)">
          {{ image.likes }}<i style="font-size: 0.5rem;" class="fas fa-heart fa-xs"></i>
          <template #animate>
            <i class="fas fa-heart fa-xs"></i>
          </template>
        </vs-button>
      </template>
    </vs-card>
  </vs-card-group>
</template>

<script>
import axios from "axios";
import { imageMix } from "@/common/mixin.js";

export default {
  name: "mediaSlider",
  mixins: [imageMix],
  props: ["media"],
  data() {
    return {
      images: []
    };
  },
  methods: {
    async addLike(id) {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const event_id = this.$store.state.currentEvent.id;
      if (id && event_id) {
        const body = { id, event_id, likes: 1 };
        await apiClient
          .post("/media", body)
          .then(response => {
            this.images.forEach(image => {
              if (image.id === id) {
                image.likes += 1;
              }
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    // async addVisit(id) {
    //   this.active = !this.active;
    //   const apiClient = axios.create({
    //     baseURL: !window.location.host.includes("localhost")
    //       ? "https://api.bmyplan.com"
    //       : "http://localhost:8087" //
    //   });

    //   if (id) {
    //     const body = { id, visit: 1 };
    //     await apiClient
    //       .post("/media", body)
    //       .then(response => {
    //         this.images.forEach(image => {
    //           if (image.id === id) {
    //             image.visits += 1;
    //           }
    //         });
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    //   }
    // },

    async getData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      const id = this.$store.state.currentEvent.id;
      if (id) {
        const body = {
          params: { event_id: id }
        };

        await apiClient
          .get("/media", body)
          .then(response => {
            var data = response.data;
            this.images = this.parseMedia(data);
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
  },
  mounted() {
    this.getData();
  }
};
</script>
