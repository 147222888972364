<template>
  <div class="pollService">
    <div class="PollsHeader">
      <h4>
        Encuestas
      </h4>
      <vs-button circle icon floating @click.stop="editQuestion('', null, 0)">
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>

    <vs-dialog not-center v-model="active">
      <template #header>
        <h4 class="not-margin">{{ mode === 1 ? "Editar" : "Nueva" }} encuesta</h4>
      </template>

      <div class="con-content title">
        <vs-input label-placeholder="Titulo de la pregunta" v-model="currentTitle"></vs-input>
      </div>
      <div class="optionsList">
        <vs-button size="small" @click="addOptionType(1)">
          <i class="fas fa-check-circle"></i>
          Selección
        </vs-button>
        <vs-button size="small" @click="addOptionType(2)">
          <i class="fas fa-toggle-on"></i>
          Switch
        </vs-button>
        <vs-button size="small" @click="addOptionType(3)">
          <i class="fas fa-check-square"></i>
          Selección Múltiple
        </vs-button>
      </div>

      <div v-for="(option, ind) in options" :key="'option_' + ind">
        <div v-if="option.type === 1" class="option1">
          <div v-for="(value, ind) in option.values" :key="'value_' + ind">
            <vs-radio warn v-model="option.selected" :val="option.values[ind]">
              {{ option.values[ind] }}
            </vs-radio>
            <vs-input :v-model="option.values[ind]"></vs-input>
          </div>
          <vs-button
            warn
            size="mini"
            :active="true"
            @click="() => option.values.push('Nueva Opción')"
          >
            Más Opciones
          </vs-button>
        </div>
        
        <div v-if="option.type === 2" class="option2">
          <vs-input v-model="option.values[0]"></vs-input>
          <vs-switch v-model="option.selected">
            <template #off>
              {{ option.values[0] }}
            </template>
            <template #on>
              {{ option.values[1] }}
            </template>
          </vs-switch>
          <vs-input v-model="option.values[1]"></vs-input>
        </div>
        <div v-if="option.type === 3" class="option1">
          <div v-for="(value, ind) in option.values" :key="'value_' + ind">
            <vs-checkbox warn v-model="option.selected" :val="option.values[ind]">
              <template #icon>
                <i class="fas fa-check"></i>
              </template>
              {{ option.values[ind] }}
            </vs-checkbox>
            <vs-input :v-model="option.values[ind]"></vs-input>
          </div>
          <vs-button
            warn
            size="mini"
            :active="true"
            @click="() => option.values.push('Nueva Opción')"
          >
            Más Opciones
          </vs-button>
        </div>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="onDelete" danger>
            Delete
          </vs-button>
          <vs-button @click="onSave" transparent success>
            Save
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-alert
      warn
      :hidden-content.sync="question.hidden"
      v-for="(question, ind) in polls"
      :key="'question_' + ind"
      class="alert_selection alertCard"
    >
      <template #title>
        <div class="titleRow">
          <h5>{{ question.title }}</h5>
        </div>
        <div class="btnRow">
          <vs-button color="#ff3e4e" circle icon floating @click.stop="addLike(ind)">
            {{ question.likes }}<i style="font-size: 0.5rem;" class="fas fa-heart fa-xs"></i>
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button circle icon floating @click.stop="editQuestion(question.title, ind, 1)">
            <i class="fas fa-pencil-alt"></i>
            <template #animate>
              <i class="fas fa-pencil-alt"></i>
            </template>
          </vs-button>
        </div>
      </template>

      <ul v-for="(option, i) in question.options" :key="'option_ok_' + i">
        <div v-if="option.type === 1" class="option">
          <div v-for="(value, ind) in option.values" :key="'value_' + ind">
            <vs-radio warn v-model="option.selected" :val="option.values[ind]">
              {{ option.values[ind] }}
            </vs-radio>
          </div>
          <vs-button size="mini" block success>Guardar Elección</vs-button>
        </div>

        <div v-if="option.type === 2" class="option">
          <vs-switch warn v-model="option.selected">
            <template #off>
              {{ option.values[0] }}
            </template>
            <template #on>
              {{ option.values[1] }}
            </template>
          </vs-switch>
          <vs-button size="mini" block success>Guardar Elección</vs-button>
        </div>

        <div v-if="option.type === 3" class="option">
          <div v-for="(value, ind) in option.values" :key="'value_' + ind">
            <vs-checkbox warn v-model="option.selected" :val="option.values[ind]">
              <template #icon>
                <i class="fas fa-check"></i>
              </template>
              {{ option.values[ind] }}
            </vs-checkbox>
          </div>
          <vs-button size="mini" block success>Guardar Elección</vs-button>
        </div>
      </ul>

      <!-- <template #footer>
        <vs-button @click.stop="addAnswer(ind)">
          Responder
        </vs-button>
      </template> -->
    </vs-alert>
  </div>
</template>

<script>
import axios from "axios";
import { loadingMix, noficationsDialog, eventMix } from "@/common/mixin.js";

export default {
  name: "PollsService",
  props: ["event", "polls"],
  mixins: [loadingMix, noficationsDialog, eventMix],
  data() {
    return {
      active: false,
      mode: 0,
      currentInd: null,
      currentTitle: "",
      answer: "",
      options: []
    };
  },
  methods: {
    async onSave() {
      this.active = false;
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id, profileImg } = currentUser;
      if (this.currentInd || this.currentInd === 0) {
        this.$props.polls[this.currentInd].title = this.currentTitle;
      } else {
        const data = {
          type: "interaction",
          subType: "polls",
          created_by: id,
          answers: [],
          options: this.options,
          likes: 0,
          created_at: new Date(),
          title: this.currentTitle,
          authorImg: profileImg
        };
        await this.addInteractionEvent(card.id, data);
        this.$props.polls.push(data);
      }
      this.currentTitle = "";
      this.currentInd = null;
    },

    onDelete() {
      this.active = false;
      this.$props.polls.splice(this.currentInd, 1);
      this.currentTitle = "";
      this.currentInd = null;
      this.setData();
    },

    editQuestion(title, ind, mode) {
      this.currentInd = ind;
      this.currentTitle = title;
      this.mode = mode;
      this.active = true;
    },

    addOptionType(type) {
      switch (type) {
        case 1:
        case 2:
          this.options = [{ type, values: ["A", "B"], selected: "B" }];
          break;
        case 3:
          this.options = [{ type, values: ["A", "B"], selected: ["B"] }];
          break;
        default:
          break;
      }
    },

    addLike(index) {
      let currentQuestion = this.$props.polls[index];
      currentQuestion.likes += 1;
      this.setData();
    },

    addAnswer(index) {
      let currentQuestion = this.$props.polls[index];
      if (!currentQuestion.hasOwnProperty("answers")) {
        currentQuestion.answers = [];
      }
      currentQuestion.answers.push(this.answer);
      this.setData();
      this.answer = "";
    },

    async setData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087"
      });

      const id = this.$store.state.currentEvent.id;

      if (id) {
        const body = { event_id: id, content: JSON.stringify(this.questions) };

        await apiClient
          .put("/polls", body)
          .then(response => {})
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
.PollsHeader,
.btnRow,
.con-footer {
  display: flex;
  justify-content: space-between;
}

.btnRow {
  width: 100%;
  justify-content: flex-end;
}

.titleRow {
  width: 100%;
  text-align: justify;
}

.title {
  display: flex;
  justify-content: center;
}

.option1,
.option2 {
  margin: 0.5rem 0rem;
  display: grid;
  justify-content: space-evenly;
}

.option2 {
  display: flex;
}

.optionsList {
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

.options {
  height: 100px;
  width: 100px;
  background: lightblue;
  border-radius: 2rem;
}

.alert_selection {
  height: auto !important;
}

.alertCard {
  background: linear-gradient(232deg, #dc3545, #e8394a);
  color: white;
  border-bottom: 2px solid white;
}

.pollService {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid red;
}

li {
  text-align: justify;
}
</style>
