<template>
  <div class="listService">
    <div class="listsHeader">
      <h4>
        Listas
      </h4>
      <vs-button @click="addList" circle icon floating>
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>
    <div class="listGrid">
      <b-card class="listsCard" v-for="(list, ind) in lists" :key="ind">
        <template #header>
          <div>
            <b>{{ list.title }}</b>
          </div>
        </template>
        <div class="lists">
          <ul>
            <a>
              <li v-for="(item, ind) in list.elements" :key="'item_' + ind">
                <div class="elementCnt">
                  {{ item.content }}
                  <vs-avatar circle size="20">
                    <img
                      loading="lazy"
                      :src="require('@/assets/Avatar-Sizes-Custom-1.jpg')"
                      alt="Avatar"
                    />
                  </vs-avatar>
                </div>
              </li>
            </a>
          </ul>
        </div>
        <template #footer class="listFooter" v-if="owner">
          <vs-button icon border block mini dark @click="active = !active">
            <i class="fas fa-pencil-alt"></i>
          </vs-button>
        </template>
      </b-card>
    </div>
    <vs-dialog width="550px" not-center v-model="active">
      <template #header>
        <h4 class="not-margin">Nueva <b>Lista</b></h4>
      </template>

      <div class="con-content">
        <div class="listEH">
          <vs-input label-placeholder="Nombre de la lista" v-model="title"></vs-input>
        </div>
        <div class="listEH">
          <h6 class="mb-0">Lista de elementos:</h6>
        </div>
        <div v-for="(element, ind) in elements" :key="ind" class="listItemE">
          <vs-button
            @click.prevent="deleteItem(ind)"
            class="listItemEB"
            circle
            mini
            danger
            border
            transparent
            >x</vs-button
          >
          <vs-button class="listItemEB" square mini dark border transparent>::</vs-button>
          <vs-input label-placeholder="Elemento de la lista" v-model="element.content"></vs-input>
        </div>
        <vs-button @click="addItem">+</vs-button>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="saveList" transparent success>
            Save
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, eventMix } from "@/common/mixin.js";

export default {
  name: "listService",
  props: ["lists", "owner"],
  mixins: [loadingMix, noficationsDialog, eventMix],
  data() {
    return {
      active: false,
      title: null,
      elements: []
    };
  },
  methods: {
    addList() {
      this.active = true;
    },
    addItem() {
      this.elements.push({ content: null, assigned: null, complete: false });
    },
    deleteItem(ind) {
      delete this.elements[ind];
    },

    async saveList() {
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id, profileImg } = currentUser;
      const data = {
        type: "interaction",
        subType: "list",
        created_by: id,
        elements: this.elements,
        created_at: new Date(),
        title: this.title,
        authorImg: profileImg
      };
      this.$props.lists.push(data);
      this.active = false;
      await this.addInteractionEvent(card.id, data);
    }
  }
};
</script>

<style scoped>
.listService {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid red;
}

li {
  list-style-type: "⚪";
  -webkit-padding-start: 1ch;
  padding-inline-start: 1ch;
  text-align: start;
}

.listsCard {
  border-radius: 1rem;
  background: #dc3545;
  min-width: 100px;
  width: 100%;
  padding: 0.5rem;
  box-shadow: 2px 3px 8px 5px rgb(0 0 0 / 38%);
}

.listsCard > .card-body {
  padding-bottom: 0;
  color: var(--main-bg);
}

.listsCard > .card-header {
  color: var(--main-bg);
}

.listGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  grid-gap: 0.75rem;
}

.listsCard > .card-footer {
  padding: 0 0.5rem 0 0;
}

.listEH {
  margin-bottom: 1rem;
}

.listItemE {
  display: flex;
  margin-bottom: 0.5rem;
}

.listItemEB {
  margin: 0;
}

.elementCnt {
  display: flex;
  justify-content: space-between;
}

div > .vs-input {
  width: 100% !important;
  min-width: 100%;
}

.listsHeader {
  display: flex;
  justify-content: space-between;
}
</style>
