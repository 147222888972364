<template>
  <b-card class="servicesCard">
    <div class="servicesCnt">
      <vs-button
        icon
        v-for="(service, ind) in servicesList"
        :key="ind"
        :color="service.color"
        v-b-toggle="'collapse-' + ind + '-service'"
        :title="service.name"
      >
        <i :class="service.icon"></i>
      </vs-button>
    </div>
    <div v-for="(service, ind) in servicesList" :key="ind">
      <b-collapse :id="'collapse-' + ind + '-service'" class="mt-2">
        <b-card>{{ service.name }}</b-card>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "ServicesPreview",
  data() {
    return {
      servicesList: [
        { name: "Listas", color: "#ba324f", icon: "fas fa-clipboard-list" },
        { name: "Tareas y asignaciones", color: "#7fdeff", icon: "fas fa-tasks" },
        { name: "Encuestas y votos", color: "#907ad6", icon: "fas fa-vote-yea" },
        // { name: "Pagos", color: "#4f518c", icon: "fas fa-comment-dollar" },
        { name: "Valoraciones", color: "#2c2a4a", icon: "far fa-star" },
        { name: "Compromisos y confirmaciones", color: "#96e6b3", icon: "fas fa-file-signature" },
        { name: "Datos y gráficos", color: "#f2e94e", icon: "fas fa-chart-pie" }
      ]
    };
  }
};
</script>

<style scoped>
.servicesCnt {
  display: flex;
  justify-content: center;
}

.servicesCard {
  width: 100%;
}
</style>
