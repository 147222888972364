<template>
  <div class="anaService">
    <div class="AandAHeader">
      <h4>
        Servicios de Administrador
      </h4>
      <!-- <vs-button circle icon floating @click.stop="editQuestion('', null, 0)">
        <i class="fas fa-plus-circle"></i>
      </vs-button> -->
    </div>

    <div
      class="participantDiv"
      v-for="(participant, ind) in aParticipants"
      :key="'participant_' + ind"
    >
      <div class="avatarParticipant">
        <img width="50" src="https://cdn-icons-png.flaticon.com/512/147/147140.png" alt="" />
      </div>
      <div style="padding: 1rem 0;">{{ participant.nombre + " " + participant.apellidos }}</div>
      <div class="actionsBtns">
        <vs-button transparent color="success" floating size="mini" icon>
          <i class="fas fa-user-check"></i>
        </vs-button>
        <vs-button transparent color="danger" floating size="mini" icon>
          <i class="fas fa-user-minus"></i>
        </vs-button>
        <vs-button transparent floating size="mini" icon>
          <i class="fas fa-comment-slash"></i>
        </vs-button>
        <vs-button transparent floating size="mini" icon>
          <i class="fas fa-eye-slash"></i>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { loadingMix, noficationsDialog, eventMix, authMix } from "@/common/mixin.js";

export default {
  name: "AdminService",
  props: ["participants"],
  mixins: [loadingMix, noficationsDialog, eventMix, authMix],
  data() {
    return {
      aParticipants: [],
      active: false,
      mode: 0,
      currentInd: null,
      currentTitle: "",
      answer: ""
    };
  },
  methods: {
    async initData() {
      const { participants } = this.$props;
      if (participants.length > 0) {
        participants.forEach(async part => {
          const participant = await this.firebaseGetUser(part);
          this.aParticipants.push(participant);
        });
      }
      console.log(this.aParticipants);
    },
    async setData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const id = this.$store.state.currentEvent.id;

      if (id) {
        const body = { event_id: id, content: JSON.stringify(this.questions) };

        await apiClient
          .put("/questions", body)
          .then(response => {})
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  mounted() {
    this.initData();
    // console.log(this.$props);
  }
};
</script>

<style scoped>
.AandAHeader {
  padding: 1rem;
}
.AandAHeader,
.btnRow,
.con-footer {
  display: flex;
  justify-content: space-between;
}

.btnRow {
  width: 100%;
  justify-content: flex-end;
}

.titleRow {
  width: 100%;
  text-align: justify;
}

.alertCard {
  background: linear-gradient(232deg, #dc3545, #e8394a);
  color: var(--main-byp-2);
  border-bottom: 2px solid var(--main-byp-2);
}

.anaService {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid red;
}

.participantDiv {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(270deg, #e91e632b, transparent);
  border-radius: 0.5rem;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0.25rem;
}

.avatarParticipant {
  width: 20%;
  background: #be0443;
  border-radius: 0 50% 50% 0;
  padding: 0.25rem 0.5rem;
}

.actionsBtns {
  width: auto;
  display: flex;
}

li {
  text-align: justify;
}
</style>
