<template>
  <div>
    <div class="PollsHeader">
      <h4>
        Estadísticas
      </h4>
    </div>

    <vs-alert warn class="alert_selection">
      <template #title>
        <div class="titleRow">
          <h5>{{ "Gráfica 1" }}</h5>
        </div>
        <div class="btnRow">
          <vs-button color="#ff3e4e" circle icon floating>
            <i style="font-size: 0.5rem;" class="fas fa-heart fa-xs"></i>
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button circle icon floating>
            <i class="fas fa-pencil-alt"></i>
            <template #animate>
              <i class="fas fa-pencil-alt"></i>
            </template>
          </vs-button>
        </div>
      </template>
      <div>
        <line-chart v-if="loaded" :chartData="datacollection" :options="this.options"></line-chart>
      </div>
    </vs-alert>
  </div>
</template>

<script>
import axios from "axios";
import LineChart from "@/components/CHARTS/line.js";

export default {
  name: "StatsService",
  props: ["event"],
  components: { LineChart },
  data() {
    return {
      hidden: false,
      datacollection: {},
      currentInd: null,
      currentTitle: "",
      answer: "",
      loaded: false,
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: ["A", "B", "C","D"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f8d2d9",
            data: [10, 20, 30,40]
          },
          {
            label: "Data Two",
            backgroundColor: "#f87979",
            data: [15, 5, 10,10]
          }
        ]
      };

      this.loaded = true;
    },

    async getData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      const id = this.$store.state.currentEvent.id;
      if (id) {
        const body = {
          params: { event_id: id }
        };

        await apiClient
          .get("/polls", body)
          .then(response => {})
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  mounted() {
    this.getData();
    this.fillData();
  }
};
</script>

<style scoped>
.PollsHeader,
.btnRow,
.con-footer {
  display: flex;
  justify-content: space-between;
}

.btnRow {
  width: 100%;
  justify-content: flex-end;
}

.titleRow {
  width: 100%;
  text-align: justify;
}

.title {
  display: flex;
  justify-content: center;
}

.option1,
.option2 {
  margin: 0.5rem 0rem;
  display: grid;
  justify-content: space-evenly;
}

.option2 {
  display: flex;
}

.optionsList {
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

.options {
  height: 100px;
  width: 100px;
  background: lightblue;
  border-radius: 2rem;
}

.alert_selection {
  height: auto !important;
}

li {
  text-align: justify;
}
</style>
