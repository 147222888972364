<template>
  <div class="anaService">
    <div class="AandAHeader">
      <h4>
        Preguntas y respuestas
      </h4>
      <vs-button circle icon floating @click.stop="editQuestion('', null, 0)">
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>

    <vs-dialog not-center v-model="active">
      <template #header>
        <h4 class="not-margin">{{ mode === 1 ? "Editar" : "Nueva" }} pregunta</h4>
      </template>

      <div class="con-content">
        <vs-input label-placeholder="Titulo de la pregunta" v-model="currentTitle"></vs-input>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="onDelete" danger>
            Delete
          </vs-button>
          <vs-button @click="onSave" transparent success>
            Save
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-alert
      warn
      class="alertCard"
      :hidden-content.sync="question.hidden"
      v-for="(question, ind) in questions"
      :key="'question_' + ind"
    >
      <template #title>
        <div class="titleRow">
          <h5>{{ question.title }}</h5>
        </div>
        <div class="btnRow">
          <vs-button color="#ff3e4e" circle icon floating @click.stop="addLike(ind)">
            {{ question.likes }}<i style="font-size: 0.5rem;" class="fas fa-heart fa-xs"></i>
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button circle icon floating @click.stop="editQuestion(question.title, ind, 1)">
            <i class="fas fa-pencil-alt"></i>
            <template #animate>
              <i class="fas fa-pencil-alt"></i>
            </template>
          </vs-button>
        </div>
      </template>
      <ul v-for="(answer, i) in question.answers" :key="'answer_' + i">
        <li>{{ answer }}</li>
      </ul>
      <b-form-textarea
        class="divRow"
        id="textarea"
        placeholder="Escribe aquí tu respuesta..."
        rows="1"
        max-rows="6"
        v-model="answer"
      ></b-form-textarea>

      <template #footer>
        <vs-button transparent border color="white" @click.stop="addAnswer(ind)">
          Responder
        </vs-button>
      </template>
    </vs-alert>
  </div>
</template>

<script>
import axios from "axios";
import { loadingMix, noficationsDialog, eventMix } from "@/common/mixin.js";

export default {
  name: "AandAService",
  props: ["questions", "owner"],
  mixins: [loadingMix, noficationsDialog, eventMix],
  data() {
    return {
      active: false,
      mode: 0,
      currentInd: null,
      currentTitle: "",
      answer: ""
    };
  },
  methods: {
    async onSave() {
      this.active = false;
      const { currentEvent, currentUser } = this.$store.state;
      const { card } = currentEvent;
      const { id, profileImg } = currentUser;
      if (this.currentInd || this.currentInd === 0) {
        this.questions[this.currentInd].title = this.currentTitle;
      } else {
        const profileImg2 =
          profileImg ||
          "https://cdn4.iconfinder.com/data/icons/evil-icons-user-interface/64/avatar-512.png";
        const data = {
          type: "interaction",
          subType: "questions",
          created_by: id,
          answers: [],
          likes: 0,
          created_at: new Date(),
          title: this.currentTitle,
          authorImg: profileImg2
        };
        await this.addInteractionEvent(card.id, data);
        this.$props.questions.push(data);
      }
      this.currentTitle = "";
      this.currentInd = null;
    },

    onDelete() {
      this.active = false;
      this.questions.splice(this.currentInd, 1);
      this.currentTitle = "";
      this.currentInd = null;
      this.setData();
    },

    editQuestion(title, ind, mode) {
      this.currentInd = ind;
      this.currentTitle = title;
      this.mode = mode;
      this.active = true;
    },

    addLike(index) {
      let currentQuestion = this.questions[index];
      currentQuestion.likes += 1;
      this.setData();
    },

    addAnswer(index) {
      let currentQuestion = this.questions[index];
      if (!currentQuestion.hasOwnProperty("answers")) {
        currentQuestion.answers = [];
      }
      currentQuestion.answers.push(this.answer);
      this.setData();
      this.answer = "";
    },

    // async getData() {
    //   const apiClient = axios.create({
    //     baseURL: !window.location.host.includes("localhost")
    //       ? "https://api.bmyplan.com"
    //       : "http://localhost:8087" //
    //   });
    //   const id = this.$store.state.currentEvent.id;
    //   if (id) {
    //     const body = {
    //       params: { event_id: id }
    //     };

    //     await apiClient
    //       .get("/questions", body)
    //       .then(response => {
    //         var data = response.data[0];
    //         if (data) {
    //           this.questions = JSON.parse(data.content);
    //         }
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    //   }
    // },

    async setData() {
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });

      const id = this.$store.state.currentEvent.id;

      if (id) {
        const body = { event_id: id, content: JSON.stringify(this.questions) };

        await apiClient
          .put("/questions", body)
          .then(response => {})
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  mounted() {
    // this.getData();
  }
};
</script>

<style scoped>
.AandAHeader,
.btnRow,
.con-footer {
  display: flex;
  justify-content: space-between;
}

.btnRow {
  width: 100%;
  justify-content: flex-end;
}

.titleRow {
  width: 100%;
  text-align: justify;
}

.alertCard {
  background: linear-gradient(232deg, #dc3545, #e8394a);
  color: var(--main-byp-2);
  border-bottom: 2px solid var(--main-byp-2);
}

.anaService {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid red;
}

li {
  text-align: justify;
}
</style>
