<template>
  <div>
    <div class="paymentHeader">
      <h4>
        Micropagos
      </h4>
      <vs-button @click="addPayment" circle icon floating>
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>
    <div id="paycardG">
      <vs-card type="3">
        <template #title>
          <h3>El bote a pagar es :</h3>
        </template>
        <template #img>
          <img src="https://www.tuitionexpress.com/images/te-illustration-bill-pay.png" alt="" />
        </template>
        <template #text>
          <h1>5€</h1>
          <vs-button @click="doPaymentRequest" block circle floating icon color="dark">
            Pagar Ahora!
          </vs-button>
        </template>
        <!-- <template #interactions>
          <vs-button @click="doPaymentRequest" block circle floating icon color="dark">
            Pagar Ahora!
          </vs-button>
        </template> -->
      </vs-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentService",
  methods: {
    async doPaymentRequest() {
      try {
        const request = new PaymentRequest(this.methodData, this.details, this.options);
        // See below for a detailed example of handling these events
        request.onshippingaddresschange = ev => ev.updateWith(this.details);
        request.onshippingoptionchange = ev => ev.updateWith(this.details);
        const response = await request.show();
        await validateResponse(response);
      } catch (err) {
        // AbortError, SecurityError
        console.error(err);
      }
    },
    async validateResponse(response) {
      try {
        const errors = await checkAllValuesAreGood(response);
        if (errors.length) {
          await request.retry(errors);
          return validateResponse(response);
        }
        await response.complete("success");
      } catch (err) {
        // Something went wrong...
        await response.complete("fail");
      }
    },
    addPayment() {}
  },
  mounted() {
    let oDetails = {
      id: "super-store-order-123-12312",
      displayItems: [
        {
          label: "Sub-total",
          amount: { currency: "EUR", value: "5.00" }
        },
        {
          label: "Comisiones y tasas",
          amount: { currency: "EUR", value: "0.10" },
          type: "tax"
        }
      ],
      total: {
        label: "Total",
        amount: { currency: "EUR", value: "5.10" }
      }
    };

    let aShippingOptions = [
      {
        id: "standard",
        label: "🚛 Ground Shipping (2 days)",
        amount: { currency: "USD", value: "5.00" },
        selected: true
      },
      {
        id: "drone",
        label: "🚀 Drone Express (2 hours)",
        amount: { currency: "USD", value: "25.00" }
      }
    ];

    let oCardFee = {
      label: "Card processing fee",
      amount: { currency: "EUR", value: "1.00" }
    };

    let aModifiers = [
      {
        additionalDisplayItems: [oCardFee],
        supportedMethods: "basic-card",
        total: {
          label: "Total due",
          amount: { currency: "USD", value: "68.00" }
        },
        data: {
          supportedNetworks: []
        }
      }
    ];

    let aMethodData = [
      {
        supportedMethods: "basic-card",
        data: {
          supportedNetworks: ["visa", "mastercard"]
        }
      },
      {
        supportedMethods: "https://example.com/bobpay",
        data: {
          merchantIdentifier: "XXXX",
          bobPaySpecificField: true
        }
      }
    ];

    Object.assign(oDetails, {
      shippingOptions: aShippingOptions
    });
    Object.assign(oDetails, {
      modifiers: aModifiers
    });

    this.details = oDetails;
    this.methodData = aMethodData;
  },
  data() {
    return {
      methodData: [],

      shippingOptions: [],
      details: {
        id: "super-store-order-123-12312",
        displayItems: [
          {
            label: "Sub-total",
            amount: { currency: "USD", value: "55.00" }
          },
          {
            label: "Sales Tax",
            amount: { currency: "USD", value: "5.00" },
            type: "tax"
          }
        ],
        total: {
          label: "Total due",
          // The total is USD$65.00 here because we need to
          // add shipping (below). The selected shipping
          // costs USD$5.00.
          amount: { currency: "USD", value: "65.00" }
        }
      },
      // Certain cards incurs a $3.00 processing fee.
      cardFee: {},

      // Modifiers apply when the user chooses to pay with
      // a card.
      modifiers: [],

      options: {
        requestPayerEmail: false,
        requestPayerName: true,
        requestPayerPhone: false,
        requestShipping: true
      }
    };
  }
};
</script>

<style scoped>
.paymentHeader {
  display: flex;
  justify-content: space-between;
}
</style>

<style>
#paycardG > div > .vs-card {
  background: linear-gradient(232deg, #dc3545, #6b151f);
  max-width: none;
  box-shadow: 5px 5px 20px 0px rgb(0 0 0 / 60%);
  color: white;
}

#paycardG > div > .vs-card > .vs-card__img {
  max-width: 40%;
}
</style>
