<template>
  <v-speed-dial
    v-model="fab"
    :right="true"
    :bottom="true"
    :left="false"
    :top="false"
    direction="top"
    :open-on-hover="true"
    :transition="'scale'"
  >
    <template v-slot:activator>
      <vs-button v-model="fab" floating circle icon color="#dc3545">
        <i v-if="fab" class="fas fa-times"></i>
        <i v-else class="fas fa-chess"></i>
      </vs-button>
    </template>
    <vs-button
      circle
      v-for="(service, ind) in servicesList"
      :key="ind"
      floating
      icon
      :color="service.color"
      v-b-toggle="'service-' + service.slug"
    >
      <i :class="service.icon"></i>
    </vs-button>
  </v-speed-dial>
</template>

<script>
export default {
  name: "servicesFB",
  data() {
    return {
      fab: true,
      servicesList: [
        { name: "Listas", color: "#ba324f", icon: "fas fa-clipboard-list", slug: "list" },
        {
          name: "Preguntas y respuestas",
          color: "#7fdeff",
          icon: "fas fa-comment-alt",
          slug: "AandA"
        },
        { name: "Encuestas y votos", color: "#907ad6", icon: "fas fa-vote-yea", slug: "vote" },
        // { name: "Pagos", color: "#4f518c", icon: "fas fa-comment-dollar", slug: "payment" },
        { name: "Valoraciones", color: "#2c2a4a", icon: "far fa-star", slug: "rating" },
        {
          name: "Compromisos y confirmaciones",
          color: "#96e6b3",
          icon: "fas fa-file-signature",
          slug: "compromise"
        },
        { name: "Datos y gráficos", color: "#f2e94e", icon: "fas fa-chart-pie", slug: "stats" }
      ]
    };
  }
};
</script>
