<template>
  <div class="participantCtn">
    <div class="participantsListHeader">
      <h2>
        Lista de participantes
      </h2>
      <vs-button circle icon floating>
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>
    <div class="participantList gridCards2">
      <div class="participant" v-for="assistant in assistants" :key="assistant.nick">
        <vs-card type="2">
          <template #title>
            <h3>{{ assistant.nick }}</h3>
          </template>
          <template #img>
            <img loading="lazy" :src="assistant.ProfileImg" />
          </template>
          <template #text>
            <!-- Buscar como 'catalogar' a la persona o qué poner aquí -->
            <small>Familiar</small>
          </template>
          <template #interactions>
            <!-- <vs-button icon color="success">
              <i class="fas fa-user-check" fa-sm></i>
            </vs-button> -->
            <vs-button @click.stop="onDisJoin" icon color="danger">
              <i class="fas fa-trash-alt fa-xs"></i>
            </vs-button>
          </template>
        </vs-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "assistantsList",
  props: ["assistants"],
  data() {
    return {
      //   assistants: [] | this.$props.assistants
    };
  },
  methods: {
    async onDisJoin(event) {
      const event_id = this.$store.state.currentEvent.id;
      const body = {
        username: localStorage.user,
        event_id
      };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .post("/participation", body)
        .then(response => {
          this.joined = false;
          const deletedInd = this.$props.assistants.findIndex(
            assistant => assistant.id === this.$store.state.currentUser.id
          );
          this.$props.assistants.splice(deletedInd, 1);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .gridCards2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 576px) {
  .gridCards2 {
    grid-template-columns: repeat(5, 1fr);
  }
}
.gridCards2 {
  display: grid;
  grid-gap: 10px;
}

.participantCtn {
  min-width: 100%;
}

/* .participantList {
  margin-top: 2rem;
  margin-bottom: 2rem;
} */

.participantsListHeader {
  display: flex;
  justify-content: space-between;
}
</style>
