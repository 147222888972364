<template>
  <div v-if="activeSidebar">
    <vs-sidebar class="sbMenu" v-model="active" right :open.sync="activeSidebar">
      <template #logo>
        <h4>Edit event</h4>
      </template>

      <vs-sidebar-item>
        <vs-switch class="inputs" color="#056877" v-model="isPrivate">
          <template #off> Publico</template>
          <template #on> Privado <i class="fa fa-block" aria-hidden="true"></i> </template>
        </vs-switch>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <vs-input
          placeholder="Título"
          :color="title !== '' ? 'success' : '#fff'"
          label="Título"
          v-model="title"
        >
          <template v-if="title === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <vs-input
          label="Descripción"
          :color="description !== '' ? 'success' : '#fff'"
          placeholder="Descripción"
          v-model="description"
        >
          <template v-if="description === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>

      <!-- <vs-sidebar-item>
        <vs-input
          label="Temática"
          :color="tematica !== '' ? 'success' : '#fff'"
          placeholder="Temática"
          v-model="tematica"
        >
          <template v-if="tematica === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item> -->

      <!-- <vs-sidebar-item>
        <vs-input
          :color="lugar !== '' ? 'success' : '#fff'"
          label="Lugar"
          placeholder="Lugar"
          v-model="lugar"
        >
          <template v-if="lugar === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item> -->

      <!-- <vs-sidebar-item>
        <vs-input
          :color="street !== '' ? 'success' : '#fff'"
          label="Calle"
          placeholder="Calle"
          v-model="street"
        >
          <template v-if="street === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item> -->

      <vs-sidebar-item>
        <vs-input
          :color="aforo !== '' ? 'success' : '#fff'"
          label="Aforo"
          placeholder="Aforo"
          v-model="aforo"
        >
        </vs-input>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <places
          v-model="form.country.label"
          placeholder="Donde quedamos ?"
          id="input-styling-address"
          @change="
            val => {
              form.country.data = val;
            }
          "
          :options="options"
          ref="places"
        ></places>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <vs-input
          type="file"
          accept="image/*"
          placeholder="Select your image"
          drop-placeholder="Drop file here..."
          :color="Image !== '' ? 'success' : '#fff'"
          label="¿Imagen para tu plan? "
          @change="onUploadFile($event)"
          id="image"
        >
        </vs-input>
        <img loading="lazy" v-if="previewImage" :src="previewImage" class="previewImg" />
      </vs-sidebar-item>

      <template #footer>
        <vs-button block success @click="onSave"> <i class="fas fa-save"></i> Save </vs-button>
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>Close
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Places from "vue-places";

export default {
  name: "eventSettingsSB",
  props: ["event"],
  components: {
    Places
  },
  methods: {
    onSave() {
      const { isPrivate, title, description, aforo, previewImage } = this;
      const data = {
        isPrivate,
        title,
        description,
        aforo,
        image: previewImage
      };
      this.$emit("onSave", { _, data });
    },

    onCloseSB() {
      this.$store.commit("showEventSettingsSB", false);
      this.activeSidebar = false;
      this.$emit("onSave", { _, data: null });
    },

    onUploadFile(oEvent) {
      const image = oEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        this.Image = oEvent2.target.result;
      };
    }
  },
  computed: mapState(["showEventSettingsSB"]),
  watch: {
    showEventSettingsSB(newValue, oldValue) {
      this.activeSidebar = newValue;
    }
  },
  mounted() {
    const { currentEvent } = this.$store.state;

    if (currentEvent.card) {
      const { title, description, location, isPrivate, aforo, image } = currentEvent.card;
      // this.form.country.label = currentEvent.Location
      //   ? JSON.parse(currentEvent.Location).value
      //   : currentEvent.location
      //   ? JSON.parse(currentEvent.location).value
      //   : null;
      // this.street = currentEvent.street;
      // this.lugar = currentEvent.city;
      this.title = title;
      this.isPrivate = isPrivate;
      this.description = description;
      this.aforo = aforo;
      this.previewImage = image;
    }
  },
  data() {
    return {
      variant: "info",
      active: "test",
      activeSidebar: this.$store.state.showEventSettingsSB || false,
      isPrivate: false,
      title: "",
      description: "",
      aforo: 0,
      previewImage: null,
      Image: "",
      options: {
        appId: null,
        apiKey: "3c3a5a672d3bc94b9f3c1cf97c6aab63",
        // postcodeSearch: true,
        countries: ["es"],
        useDeviceLocation: true
      },
      form: {
        country: {
          label: "",
          data: {}
        }
      }
    };
  }
};
</script>

<style scoped>
.previewImg {
  height: 50px;
  width: 50px;
}

@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
    border-radius: 30px 0px 0px 30px;
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 70px;
    max-height: calc(100% - 94px);
  }
}
</style>
